exports.components = {
  "component---pages-privacy-index-mdx": () => import("./../../../pages/privacy/index.mdx" /* webpackChunkName: "component---pages-privacy-index-mdx" */),
  "component---pages-security-index-mdx": () => import("./../../../pages/security/index.mdx" /* webpackChunkName: "component---pages-security-index-mdx" */),
  "component---pages-terms-index-mdx": () => import("./../../../pages/terms/index.mdx" /* webpackChunkName: "component---pages-terms-index-mdx" */),
  "component---src-components-blog-post-jsx": () => import("./../../../src/components/blogPost.jsx" /* webpackChunkName: "component---src-components-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */)
}

